import React, { Component } from 'react';
import {BrowserRouter} from 'react-router-dom'
// import { HashRouter } from 'react-router-dom'

import Routes from './Routes'

import 'semantic-ui-css/semantic.min.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    );
  }
}

export default App;
